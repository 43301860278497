const About = () => {
  return (
    <section className="bg-white w-full min-h-screen md:py-20 flex flex-col items-center justify-center font-mono">
      <div className="flex flex-col items-center justify-center">
        <h2 className="pt-28 mb-8 text-xl lg:text-2xl font-bold text-dark-green">
          <span aria-hidden="true" className="mr-2">
            👩🏻‍💻
          </span>
          About me
        </h2>
        <div className="bg-grey p-3 w-10/12 md:w-3/4 lg:w-1/3 md:p-12 border-2 border border-black rounded-xl shadow-custom-2 text-justify text-xs md:text-base">
          <p className="px-4 my-4">I'm a full-stack software developer based in the North of Scotland.
            I love to build robust, high-quality solutions, with care and consideration for the user experience.
            When I'm not coding, you'll probably find me outside running on trails, walking in the forest, or on my bike.</p>
          <p className="px-4 my-6">If you want to find out more about me, you can read my article series on <a href="https://www.linkedin.com/in/nicolelancaster/recent-activity/articles/" target="_blank" rel="noreferrer"><u className="hover:text-dark-green">my journey into tech</u></a>. </p>
        </div>
      </div>
    </section>
  )
}

export default About