import { useRef } from "react";
import NavBar from "./components/sections/NavBarSection/NavBar.jsx";
import Home from "./components/sections/HomeSection/Home.jsx";
import About from "./components/sections/AboutSection/About.jsx"
import Projects from "./components/sections/ProjectsSection/Projects.jsx";
import Skills from "./components/sections/SkillsSection/Skills.jsx";
import Contact from "./components/sections/ContactSection/Contact.jsx";

function App() {
  const skillsSectionRef = useRef();
  const projectsSectionRef = useRef();
  const contactSectionRef = useRef();
  const homeSectionRef = useRef();
  const aboutSectionRef = useRef();

  return (
    <>
      <NavBar
        homeSectionRef={homeSectionRef}
        aboutSectionRef={aboutSectionRef}
        skillsSectionRef={skillsSectionRef}
        projectsSectionRef={projectsSectionRef}
        contactSectionRef={contactSectionRef}
      />
      <main className="bg-white">
        <section ref={homeSectionRef}>
          <Home className="flex flex-col" />
        </section>
        <section ref={aboutSectionRef}>
          <About />
        </section>
        <section ref={skillsSectionRef}>
          <Skills />
        </section>
        <section ref={projectsSectionRef}>
          <Projects />
        </section>
        <section ref={contactSectionRef}>
          <Contact />
        </section>
      </main>
    </>
  );
}

export default App;
