import nicolesNewsLaptopView from "../../../assets/nicolesNewsLaptopView.png";
import apiCalls from "../../../assets/nicoles-news-API.png";
import cityLegends from "../../../assets/cityLegends.png";
import urlShortener from "../../../assets/URL_shortener.png";
import exerciseTracker from "../../../assets/exerciseTracker.png";
import comingSoon from "../../../assets/comingSoon.png";
import fileMetadataProject from "../../../assets/fileMetadata.png";
import timestampMicroservice from "../../../assets/timestampMicroservice.png";
import requestHeadersMicroservice from "../../../assets/requestHeadersMicroservice.png";

const projectsList = [
  {
    title: "Exercise Tracker Web Application",
    stack: "Front End",
    description: "A web application where users can create a user profile to view, submit and track their exercises.",
    techUsed: "TypeScript, HTML5, React, ChakraUI",
    img: comingSoon,
  },
  {
    title: "Exercise Tracker API",
    stack: "Back End",
    description: "A REST API integrated with a server and which stores and retrieve users' usernames and exercise data. Includes a basic, server-side rendered user interface",
    techUsed: "TypeScript, HTML5, CSS3, Node.js, Express.js, MongoDB, Mongoose",
    img: exerciseTracker,
    code_url: "https://github.com/nicole-lancaster/exercise-tracker-project",
    demo_url: "https://nicoles-exercise-tracker.nicole-lancaste.repl.co/",
  },
  {
    title: "URL Shortener Microservice",
    stack: "Back End",
    description: "A microservice that allows users to shorten long and difficult to remember URLs, providing a concise and easily shareable alternative.",
    techUsed: "TypeScript, HTML5, CSS3, Node.js, Express.js, MongoDB, Mongoose",
    img: urlShortener,
    code_url: "https://github.com/nicole-lancaster/url-shortener-microservice",
    demo_url: "https://url-shortener-microservice.nicole-lancaste.repl.co/api",
  },
  {
    title: "Nicole's News",
    stack: "Front End",
    description: "A Reddit-style news web application where users can view, comment on, like and filter articles on various topics, with basic 'pretend user' login/logout functionality.",
    techUsed: "JavaScript, HTML5, React, TailwindCSS, HeadlessUI, Jest, Figma",
    img: nicolesNewsLaptopView,
    code_url: "https://github.com/nicole-lancaster/nc-news",
    demo_url: "https://nicoles-news.netlify.app",
  },
  {
    title: "Nicole's News REST API",
    stack: "Back End",
    description: "A REST API for Nicole's News, which serves up data such as articles, users and comments on multiple endpoints.",
    techUsed: "JavaScript, Node.js, Express.js, PostgreSQL, Jest",
    img: apiCalls,
    code_url: "https://github.com/nicole-lancaster/nicoles-news-API",
  },
  {
    title: "City Legends",
    stack: "Full Stack",
    description: "A full stack mobile application built as part of a small team. Users can use the built-in map to view and explore stories and facts, and includes a 'discovery mode' feauture, using geolocation technology.",
    techUsed: "TypeScript, React Native, React Native Maps, Firebase, Expo",
    img: cityLegends,
    code_url: "https://github.com/Daniel17971/city-legends-frontend",
    demo_url: "https://northcoders.com/projects/may-2023/city-legends",
  },
  {
    title: "File Metadata Microservice",
    stack: "Back End",
    description: "A microservice where users can upload a file a receive a JSON response containing the metadata of that file including the file name and size in bytes.",
    techUsed: "TypeScript, HTML5, CSS3, Node.js, Express.js",
    img: fileMetadataProject,
    code_url: "https://github.com/nicole-lancaster/file-upload-microservice",
  },
  {
    title: "Request Header Parser Microservice",
    stack: "Back End",
    description: "A microservice where users can visit a URL which then responds with a JSON object containing information on the user's IP address, software, and preferred language.",
    techUsed: "TypeScript, HTML5, CSS3, Node.js, Express.js",
    img: requestHeadersMicroservice,
    code_url: "https://github.com/nicole-lancaster/request-header-parser",
  },
  {
    title: "Timestamp Microservice",
    stack: "Back End",
    description: "A microservice where users can enter a date and receive a JSON object with a unix timestamp and UTC representation of that date.",
    techUsed: "TypeScript, HTML5, CSS3, Node.js, Express.js",
    img: timestampMicroservice,
    code_url: "https://github.com/nicole-lancaster/timestamp-microservice",
  },
];

export default projectsList;
