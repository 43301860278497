import { AtSymbolIcon, EnvelopeIcon } from "@heroicons/react/24/outline";
import gitHubLogo from "../../../assets/github-mark.png";
import linkedInLogo from "../../../assets/linkedInIcon.png";
import calendlyLogo from "../../../assets/calendlyLogo.png";

const GetInTouch = () => {
  return (
    <section className="bg-white h-screen p-6 pt-20 lg:pt-28 lg:pb-20 flex flex-col justify-center items-center text-sm md:text-base lg:text-lg font-mono">
      <div className="bg-grey w-11/12 md:w-1/2 p-2 lg:p-12 border-2 border border-black rounded-xl shadow-custom-2">
        <div className="flex flex-row items-center p-2">
          <EnvelopeIcon className="h-6 w-auto mr-1 md:mr-2" aria-hidden="true" />
          <p className="flex flex-row items-center">
            lancasternj
            <AtSymbolIcon
              className="w-3 h-3 md:w-5 md:h-5 items-center text-dark-green"
              aria-hidden="true"
            />
            gmail.com
          </p>
        </div>
        <div className="flex flex-row p-2">
          <a
            href={"https://github.com/nicole-lancaster"}
            target="_blank"
            rel="noreferrer"
            className="flex flex-row items-center hover:underline"
          >
            <img src={gitHubLogo} alt="GitHub logo" className="h-6 mr-3" />
            <p>check out my GitHub</p>
          </a>
        </div>
        <div className="flex flex-row p-2">
          <a
            href={"https://www.linkedin.com/in/nicole-lancaster-614929142/"}
            target="_blank"
            rel="noreferrer"
            className="flex flex-row items-center hover:underline"
          >
            <img src={linkedInLogo} alt="linkedIn Logo" className="h-6 mr-2" />
            <p>message me on Linkedin</p>
          </a>
        </div>
        <div className="flex flex-row">
          <a
            href={"https://calendly.com/lancasternj"}
            target="_blank"
            rel="noreferrer"
            className="flex flex-row items-center hover:underline"
          >
            <img src={calendlyLogo} alt="linkedInLogo" className="h-10 mr-2" />
            <p>schedule a meeting</p>
          </a>
        </div>
      </div>
    </section>
  );
};

export default GetInTouch;
