import { useState } from "react";
import {
  Bars3Icon,
  XMarkIcon,
  CommandLineIcon,
} from "@heroicons/react/24/outline";
import { Dialog, Disclosure } from "@headlessui/react";

const NavBar = ({
  homeSectionRef,
  aboutSectionRef,
  skillsSectionRef,
  projectsSectionRef,
  contactSectionRef,
}) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const navMap = {
    Home: homeSectionRef,
    About: aboutSectionRef,
    Skills: skillsSectionRef,
    Projects: projectsSectionRef,
    Contact: contactSectionRef,
  };

  return (
    <header className="bg-white fixed w-full z-9999 top-0 left-0 font-mono font-bold cursor-pointer text-dark-green">
      <nav
        className="flex items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div
          onClick={() => {
            homeSectionRef.current.scrollIntoView({
              behavior: "smooth",
            });
          }}
          className="flex lg:flex-1"
        >
          <CommandLineIcon className="h-7 w-auto mr-2" aria-hidden="true" />
          <h1 className="text-lg sm:text-xl font-bold focus:underline">
            Nicole Lancaster
          </h1>
        </div>

        {/* mobile view */}
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-3 p-3 inline-flex items-center justify-center"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>

        {/* opening 3 bar button on mobile view */}
        <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-5000 font-mono">
            <Dialog.Panel className="bg-grey fixed inset-y-0 right-0 z-1000 w-full overflow-y-auto p-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10 text-dark-green">
              <div className="flex items-center justify-between">
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-700 focus:outline-1"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="space-y-2 py-6">
                    <Disclosure as="div" className="-mx-3">
                      {({ open }) => (
                        <ul className="cursor-pointer">
                          {Object.entries(navMap).map(
                            ([listItem, listItemValue]) => {
                              return (
                                <li
                                  key={listItem}
                                  onClick={() => {
                                    setMobileMenuOpen(false);
                                    listItemValue.current.scrollIntoView();
                                  }}
                                  className="p-1"
                                >
                                  {listItem}
                                </li>
                              );
                            }
                          )}
                        </ul>
                      )}
                    </Disclosure>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </div>
        </Dialog>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <ul className="p-2 flex flex-row items-center text-lg font-semibold cursor-pointer">
            {Object.entries(navMap).map(([listItem, listItemValue]) => {
              return (
                <li
                  key={listItem}
                  onClick={() => {
                    listItemValue.current.scrollIntoView({
                      behavior: "smooth",
                    });
                  }}
                  className="p-3 hover:underline"
                >
                  {listItem}
                </li>
              );
            })}
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default NavBar;
