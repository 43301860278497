import htmlLogo from "../../../assets/techLogos/newHTMLlogo.svg.png";
import cssLogo from "../../../assets/techLogos/cssLogo.png";
import javascriptLogo from "../../../assets/techLogos/javascriptLogo.png";
import reactLogo from "../../../assets/techLogos/reactLogo.png";
import tailwindLogo from "../../../assets/techLogos/tailwindLogo.png";
import nodeLogo from "../../../assets/techLogos/nodeLogo.png";
import sqlLogo from "../../../assets/techLogos/sqlLogo.png";
import postgresLogo from "../../../assets/techLogos/postgresLogo.png";
import jestLogo from "../../../assets/techLogos/jestLogo.png";
import expressLogo from "../../../assets/techLogos/expressLogo.png";
import typescriptLogo from "../../../assets/techLogos/typescriptLogo.png";
import mongodbLogo from "../../../assets/techLogos/mongodbLogo.png";
import netlifyLogo from "../../..//assets/techLogos/netlifyLogo.png";
import gitLogo from "../../..//assets/techLogos/gitLogo.png";
import figmaLogo from "../../..//assets/techLogos/figmaLogo.png";
import githubLogo from "../../..//assets/techLogos/githubLogo.png";
import chakraLogo from "../../../assets/techLogos/chakra.png"

const Skills = () => {
  const icons = [
    htmlLogo,
    cssLogo,
    javascriptLogo,
    typescriptLogo,
    reactLogo,
    nodeLogo,
    tailwindLogo,
    chakraLogo,
    expressLogo,
    jestLogo,
    sqlLogo,
    postgresLogo,
    mongodbLogo,
    figmaLogo,
    netlifyLogo,
    gitLogo,
    githubLogo,
  ];
  return (
    <section className="bg-white w-full min-h-screen md:py-20 flex flex-col items-center justify-center font-mono">
      <div className="w-4/5 lg:w-1/3 flex flex-col items-center justify-center ">
        <h2 className="pt-28 mb-8 text-xl lg:text-2xl font-bold text-dark-green">
          <span aria-hidden="true" className="mr-2">
            🛠️
          </span>
          Tech & Tools
        </h2>
        <div className="flex flex-row flex-wrap justify-center items-center">
          {icons.map((icon) => {
            if (
              icon === expressLogo ||
              icon === netlifyLogo ||
              icon === gitLogo ||
              icon === figmaLogo
            ) {
              return (
                <img
                  alt={icon}
                  src={icon}
                  key={icon}
                  className="w-22 lg:w-26  h-14 lg:h-18 m-2 lg:m-4 hover:animate-wiggle"
                ></img>
              );
            } else if (icon === tailwindLogo) {
              return (
                <img
                  alt={icon}
                  src={icon}
                  key={icon}
                  className="w-16 lg:w-20 h-12 lg:h-16 m-2 lg:m-4 hover:animate-wiggle"
                ></img>
              );
            } else if (icon === chakraLogo) {
              return (
                <img alt={icon}
                  src={icon}
                  key={icon}
                  className="w-24 lg:w-32 h-8 lg:h-12 m-2 lg:m-4 hover:animate-wiggle" />
              )
            } else {
              return (
                <img
                  alt={icon}
                  src={icon}
                  key={icon}
                  className="w-16 lg:w-20 h-16 lg:h-20 m-2 lg:m-4 hover:animate-wiggle"
                ></img>
              );
            }
          })}
        </div>
      </div>
    </section>
  );
};

export default Skills;
