const StackBadge = ({ stack }) => {
  return (
    <>
      <span className="">
        <span className="bg-grey text-xs lg:text-base font-semibold">{stack}</span>
      </span>
    </>
  )
}

export default StackBadge