import projectsList from "./ProjectList.jsx";
import SingleProject from "./SingleProject.jsx";

const Projects = () => {
  return (
    <>
      <section className="bg-grey w-full min-h-screen flex flex-col justify-center items-center font-mono">
        {" "}
        <h2 className="pt-32 my-4 flex flex-row text-center text-xl lg:text-2xl font-bold text-dark-green">
          <span className="mr-2" aria-hidden="true">🚀</span> Projects
        </h2>
        <div className="md:w-3/4 lg:w-2/3">
          <div className="p-2 flex flex-col lg:flex-wrap items-center">
            {projectsList.map((project) => {
              return <SingleProject key={project.title} project={project} />;
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Projects;
