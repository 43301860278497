import ButtonLinks from "./ButtonLinks.jsx";
import StackBadge from "./StackBadge.jsx";

const SingleProject = ({ project }) => {
  return (
    <div
      key={project.title}
      className="bg-white md:w-11/12 md:h-11/12 p-4 m-4 flex flex-col md:flex-row border-2 border-black shadow-custom-2 rounded-xl"
    >
      <a
        href={project.demo_url ? project.demo_url : project.code_url}
        target="_blank"
        rel="noreferrer"
        className="flex-shrink-0 md:w-1/2 focus:visible"
      >
        <img
          src={project.img}
          alt={project.img}
          className="w-full h-full object-cover rounded-xl focus:visible"
        ></img>
      </a>
      <div className="flex flex-col justify-between md:pl-6">
        <div className="p-2 md:px-2 font-mono">
          <h3 className="text-sm md:text:base lg:text-xl font-bold">
            {project.title}
          </h3>
          <StackBadge stack={project.stack} />
          <p className="mt-4 text-xs lg:text-base">{project.description}</p>
          <br />
          <p className="text-xs lg:text-base">{project.techUsed}</p>
        </div>
        <ButtonLinks codeUrl={project.code_url} demoUrl={project.demo_url} />
      </div>
    </div>
  );
};

export default SingleProject;
