const ButtonLinks = ({ codeUrl, demoUrl }) => {
  return (
    <div className="flex justify-center md:justify-end">
      {codeUrl ? (
        <a href={codeUrl} target="_blank" rel="noreferrer">
          <button className="bg-dark-green hover:bg-grey hover:text-black p-1.5 mx-2 my-2 border-white rounded text-xs text-white font-semibold">
            Code
          </button>
        </a>
      ) : null}
      {demoUrl ? (
        <a href={demoUrl} target="_blank" rel="noreferrer">
          <button className="bg-dark-green hover:bg-grey hover:text-black p-1.5 mx-2 my-2 border-white rounded text-xs text-white font-semibold">
            Demo
          </button>
        </a>
      ) : null}
    </div>
  );
};

export default ButtonLinks;
