import profilePic from "../../../assets/profilePic.png";

const Home = () => {
  return (
    <section className="bg-grey w-full min-h-screen flex flex-col items-center justify-center font-mono">
      <div className="w-5/6 flex flex-col items-center">
        <div className="py-4">
          <img
            src={profilePic}
            alt="me"
            className="w-28 h-auto md:w-32 md:h-32 border-black rounded-full shadow-custom"
          ></img>
        </div>{" "}
        <div className="py-2 md:py-4 text-center text-base lg:text-2xl text-dark-green font-semi-bold">
          <p>
            Hi, I'm Nicole <span aria-hidden="true">👋</span>
          </p>
          <p className="py-2">
            Full Stack Software Engineer <span aria-hidden="true">💻</span>
          </p>
        </div>
      </div>
    </section>
  );
};

export default Home;
